import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { inject, Injectable } from '@angular/core';
import { CrmConfigService } from 'common-module/core';
import { cloneDeep } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
class CrmApiEndpoint extends CrmEndpoint {
  version(options) {
    return this.request('GET', 'version', options);
  }
  health(options) {
    return this.request('GET', 'health', options);
  }
  healthHead(options) {
    return this.request('HEAD', 'health', options);
  }
}
const VERSION_KEY = 'crm_version';
class CrmApiService {
  constructor() {
    this.configService = inject(CrmConfigService);
    const versionJson = localStorage.getItem(VERSION_KEY);
    this.version = versionJson ? JSON.parse(versionJson) : null;
  }
  /**
   * Last loaded api version.
   */
  currentVersion() {
    return this.version;
  }
  health() {
    return this.endpoint.health();
  }
  healthHead() {
    return this.endpoint.healthHead();
  }
  /**
   * Return result of GET /api with versions of BE modules
   * and version of FE app based on set environment version
   *
   * @returns
   */
  apiVersion() {
    const environment = this.configService.getConfig('environment');
    return this.endpoint.version().pipe(catchError(() => of({})), map(version => {
      const result = cloneDeep(version);
      if (!version?.release) {
        result.release = environment?.version ?? '0.0.0';
      }
      const projectName = environment.projectName ?? '-';
      const feVersion = environment.version ?? 'n/a';
      const frontendModule = {
        module: projectName,
        version: feVersion
      };
      const modules = Object.entries(result.modules ?? {}).map(([name, nameVersion]) => {
        const parts = nameVersion.split(' ');
        if (parts.length === 1) {
          parts.unshift('');
        }
        const [moduleName, moduleVersion] = parts;
        const modName = `${moduleName.length ? moduleName : name + '-module'}`;
        return {
          module: modName,
          version: moduleVersion
        };
      });
      return {
        ...result,
        modules: [frontendModule, ...modules]
      };
    }), tap(res => this.version = res));
  }
  static {
    this.ɵfac = function CrmApiService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmApiService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmApiService,
      factory: CrmApiService.ɵfac,
      providedIn: 'root'
    });
  }
}
__decorate([CrmEndpointDecorator({
  configName: 'crm',
  endpointName: 'api',
  endpoint: CrmApiEndpoint
})], CrmApiService.prototype, "endpoint", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmApiService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], {
    endpoint: []
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmApiEndpoint, CrmApiService, VERSION_KEY };
