import * as i0 from '@angular/core';
import { input, Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
class CrmLogoComponent {
  constructor() {
    this.logoUrl = input();
    this.altTitle = input('crm-logo-main');
    this.hostClass = 'crm-logo';
  }
  static {
    this.ɵfac = function CrmLogoComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmLogoComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmLogoComponent,
      selectors: [["crm-logo"]],
      hostVars: 2,
      hostBindings: function CrmLogoComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        logoUrl: [1, "logoUrl"],
        altTitle: [1, "altTitle"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 2,
      consts: [[3, "src", "alt"]],
      template: function CrmLogoComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "img", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("src", ctx.logoUrl(), i0.ɵɵsanitizeUrl)("alt", ctx.altTitle());
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmLogoComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-logo',
      template: `<img [src]="logoUrl()" [alt]="altTitle()" />`,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmLogoComponent };
