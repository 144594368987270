import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import * as i0 from '@angular/core';
import { input, computed, output, signal, inject, ElementRef, Renderer2, effect, Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { CrmLoadingComponent } from 'common-module/common';
import { CrmUnsubscribeDirective } from 'common-module/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
const _forTrack0 = ($index, $item) => $item.key;
function CrmUserProfileComponent_Conditional_0_Conditional_1_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const profile_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", profile_r1.role, " ");
  }
}
function CrmUserProfileComponent_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3)(1, "div", 4);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, CrmUserProfileComponent_Conditional_0_Conditional_1_Conditional_3_Template, 2, 1, "div", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 6);
    i0.ɵɵelement(5, "i", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const profile_r1 = i0.ɵɵnextContext();
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", profile_r1.name, " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(profile_r1.role ? 3 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("nzType", ctx_r1.isDropdownVisible() ? ctx_r1.dropdownIconVisible() : ctx_r1.dropdownIcon());
  }
}
function CrmUserProfileComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-avatar", 2);
    i0.ɵɵtemplate(1, CrmUserProfileComponent_Conditional_0_Conditional_1_Template, 6, 3);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzSrc", ctx.avatar)("nzIcon", ctx_r1.avatar().icon)("nzSize", ctx_r1.avatar().size);
    i0.ɵɵadvance();
    i0.ɵɵconditional(!ctx_r1.compact() ? 1 : -1);
  }
}
function CrmUserProfileComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-loading", 0);
  }
}
function CrmUserProfileComponent_ng_template_2_For_2_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 11);
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("nzType", item_r4.icon);
  }
}
function CrmUserProfileComponent_ng_template_2_For_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 10);
    i0.ɵɵlistener("click", function CrmUserProfileComponent_ng_template_2_For_2_Template_li_click_0_listener($event) {
      const item_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.handleItemClicked($event, item_r4));
    });
    i0.ɵɵtemplate(1, CrmUserProfileComponent_ng_template_2_For_2_Conditional_1_Template, 1, 1, "i", 11);
    i0.ɵɵelementStart(2, "span", 12);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "crmTranslate");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    let tmp_13_0;
    const item_r4 = ctx.$implicit;
    i0.ɵɵclassMapInterpolate1("crm-user-profile-item crm-user-profile-item-", item_r4.key, "");
    i0.ɵɵadvance();
    i0.ɵɵconditional(item_r4.icon ? 1 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 5, (tmp_13_0 = item_r4.title) !== null && tmp_13_0 !== undefined ? tmp_13_0 : item_r4.key), " ");
  }
}
function CrmUserProfileComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ul", 8);
    i0.ɵɵrepeaterCreate(1, CrmUserProfileComponent_ng_template_2_For_2_Template, 5, 7, "li", 9, _forTrack0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzTheme", ctx_r1.dark() ? "dark" : "light");
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r1.items());
  }
}
const BASE_CLASS = 'crm-user-profile';
class CrmUserProfileComponent extends CrmUnsubscribeDirective {
  constructor() {
    super();
    this.profile = input();
    this.items = input([]);
    this.dropdownIcon = input('more');
    this.compact = input(false);
    this.dark = input(false);
    this.dropdownIconVisible = input('more');
    this.dropdownTrigger = input('hover');
    this.dropdownPlacement = input('top');
    this.avatarConfig = input();
    this.avatar = computed(() => {
      const config = this.avatarConfig() ?? {};
      const base = config.size?.base ?? 'large';
      const compact = config.size?.compact ?? 'small';
      return {
        icon: config.icon ?? 'user',
        size: this.compact() ? compact : base
      };
    });
    this.itemClicked$ = output();
    this.isDropdownVisible = signal(false);
    this.elementRef = inject(ElementRef);
    this.overlaySettings = signal({
      width: 'auto',
      offsetX: 0,
      offsetY: 0
    });
    this.destroyCheckLeave$ = new Subject();
    const renderer = inject(Renderer2);
    const element = this.elementRef.nativeElement;
    effect(() => {
      element.className = '';
      renderer.addClass(element, BASE_CLASS);
      if (this.compact()) {
        renderer.addClass(element, `${BASE_CLASS}-compact`);
      }
      if (this.dark()) {
        renderer.addClass(element, `${BASE_CLASS}-dark`);
      }
      if (this.dropdownTrigger() === 'click') {
        renderer.addClass(element, `${BASE_CLASS}-click-trigger`);
      }
    });
  }
  onMouseEnter() {
    if (this.dropdownTrigger() === 'hover' && !this.isDropdownVisible()) {
      this.resolveOverlaySettings();
      this.isDropdownVisible.set(true);
      this.checkLeave();
    }
  }
  onClick() {
    if (this.dropdownTrigger() === 'click' && !this.isDropdownVisible()) {
      this.resolveOverlaySettings();
      this.isDropdownVisible.set(true);
    }
  }
  handleItemClicked(event, item) {
    this.isDropdownVisible.set(false);
    this.destroyCheckLeave$.next(true);
    this.itemClicked$.emit({
      event,
      item
    });
  }
  checkLeave() {
    fromEvent(document, 'mousemove').pipe(takeUntil(this.destroyCheckLeave$), takeUntil(this.destroyed$), debounceTime(100)).subscribe(event => {
      const element = event.target;
      const classes = (element?.className ?? '').split(' ');
      if (classes.some(c => c.startsWith('crm-user-profile'))) {
        return;
      }
      this.isDropdownVisible.set(false);
      this.destroyCheckLeave$.next(true);
    });
  }
  resolveOverlaySettings() {
    const width = ['top', 'bottom'].includes(this.dropdownPlacement()) ? `${this.elementRef.nativeElement.offsetWidth}px` : 'auto';
    const offsetX = this.dropdownPlacement() === 'right' ? this.elementRef.nativeElement.offsetWidth : 0;
    const offsetY = this.dropdownPlacement() === 'right' ? this.elementRef.nativeElement.offsetHeight : 0;
    this.overlaySettings.set({
      width,
      offsetX,
      offsetY
    });
  }
  static {
    this.ɵfac = function CrmUserProfileComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmUserProfileComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmUserProfileComponent,
      selectors: [["crm-user-profile"]],
      hostBindings: function CrmUserProfileComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function CrmUserProfileComponent_mouseenter_HostBindingHandler() {
            return ctx.onMouseEnter();
          })("click", function CrmUserProfileComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      inputs: {
        profile: [1, "profile"],
        items: [1, "items"],
        dropdownIcon: [1, "dropdownIcon"],
        compact: [1, "compact"],
        dark: [1, "dark"],
        dropdownIconVisible: [1, "dropdownIconVisible"],
        dropdownTrigger: [1, "dropdownTrigger"],
        dropdownPlacement: [1, "dropdownPlacement"],
        avatarConfig: [1, "avatarConfig"]
      },
      outputs: {
        itemClicked$: "itemClicked$"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 8,
      consts: [["size", "small"], ["cdkConnectedOverlay", "", 3, "backdropClick", "cdkConnectedOverlayOrigin", "cdkConnectedOverlayMinWidth", "cdkConnectedOverlayOpen", "cdkConnectedOverlayHasBackdrop", "cdkConnectedOverlayOffsetX", "cdkConnectedOverlayOffsetY", "cdkConnectedOverlayPanelClass"], [1, "crm-user-profile-avatar", 3, "nzSrc", "nzIcon", "nzSize"], [1, "crm-user-profile-content"], [1, "crm-user-profile-content-name"], [1, "crm-user-profile-content-role"], [1, "crm-user-profile-dropdown-icon"], ["nz-icon", "", 3, "nzType"], ["nz-menu", "", 1, "crm-user-profile-items", 3, "nzTheme"], ["nz-menu-item", "", 3, "class"], ["nz-menu-item", "", 3, "click"], ["nz-icon", "", 1, "crm-user-profile-item-icon", 3, "nzType"], [1, "crm-user-profile-item-title"]],
      template: function CrmUserProfileComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmUserProfileComponent_Conditional_0_Template, 2, 4)(1, CrmUserProfileComponent_Conditional_1_Template, 1, 0, "crm-loading", 0)(2, CrmUserProfileComponent_ng_template_2_Template, 3, 1, "ng-template", 1);
          i0.ɵɵlistener("backdropClick", function CrmUserProfileComponent_Template_ng_template_backdropClick_2_listener() {
            return ctx.isDropdownVisible.set(false);
          });
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = ctx.profile()) ? 0 : 1, tmp_0_0);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("cdkConnectedOverlayOrigin", ctx.elementRef)("cdkConnectedOverlayMinWidth", ctx.overlaySettings().width)("cdkConnectedOverlayOpen", ctx.isDropdownVisible())("cdkConnectedOverlayHasBackdrop", ctx.dropdownTrigger() === "click")("cdkConnectedOverlayOffsetX", ctx.overlaySettings().offsetX)("cdkConnectedOverlayOffsetY", ctx.overlaySettings().offsetY)("cdkConnectedOverlayPanelClass", ctx.dark() ? "crm-user-profile-dropdown-dark" : "crm-user-profile-dropdown");
        }
      },
      dependencies: [CrmTranslatePipe, CdkConnectedOverlay, NzIconDirective, NzAvatarComponent, CrmLoadingComponent, NzMenuDirective, NzMenuItemComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmUserProfileComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-user-profile',
      template: `
    @if (profile(); as profile) {
      <nz-avatar
        class="${BASE_CLASS}-avatar"
        [nzSrc]="profile.avatar"
        [nzIcon]="avatar().icon"
        [nzSize]="avatar().size"
      ></nz-avatar>

      @if (!compact()) {
        <div class="${BASE_CLASS}-content">
          <div class="${BASE_CLASS}-content-name">
            {{ profile.name }}
          </div>
          @if (profile.role) {
            <div class="${BASE_CLASS}-content-role">
              {{ profile.role }}
            </div>
          }
        </div>
        <div class="${BASE_CLASS}-dropdown-icon">
          <i
            nz-icon
            [nzType]="
              isDropdownVisible() ? dropdownIconVisible() : dropdownIcon()
            "
          ></i>
        </div>
      }
    } @else {
      <crm-loading size="small"></crm-loading>
    }

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="elementRef"
      [cdkConnectedOverlayMinWidth]="overlaySettings().width"
      [cdkConnectedOverlayOpen]="isDropdownVisible()"
      [cdkConnectedOverlayHasBackdrop]="dropdownTrigger() === 'click'"
      [cdkConnectedOverlayOffsetX]="overlaySettings().offsetX"
      [cdkConnectedOverlayOffsetY]="overlaySettings().offsetY"
      [cdkConnectedOverlayPanelClass]="
        dark() ? '${BASE_CLASS}-dropdown-dark' : '${BASE_CLASS}-dropdown'
      "
      (backdropClick)="isDropdownVisible.set(false)"
    >
      <ul
        class="${BASE_CLASS}-items"
        [nzTheme]="dark() ? 'dark' : 'light'"
        nz-menu
      >
        @for (item of items(); track item.key) {
          <li
            class="${BASE_CLASS}-item ${BASE_CLASS}-item-{{ item.key }}"
            nz-menu-item
            (click)="handleItemClicked($event, item)"
          >
            @if (item.icon) {
              <i
                class="${BASE_CLASS}-item-icon"
                nz-icon
                [nzType]="item.icon"
              ></i>
            }

            <span class="${BASE_CLASS}-item-title">
              {{ item.title ?? item.key | crmTranslate }}
            </span>
          </li>
        }
      </ul>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, CdkConnectedOverlay, NzIconDirective, NzAvatarComponent, CrmLoadingComponent, NzMenuDirective, NzMenuItemComponent]
    }]
  }], () => [], {
    onMouseEnter: [{
      type: HostListener,
      args: ['mouseenter']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmUserProfileComponent };
