import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { crmProvideAuth } from 'common-module/auth';
import { CrmAppConfig } from 'common-module/core';
import { crmProvideEndpoint } from 'common-module/endpoint';
import { crmProvideModal } from 'common-module/modal';
import { crmProvideTranslate } from 'common-module/translate';
import { CrmUserService } from 'common-module/user';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { UserService } from '~/api/user/user.service';
import { authConfig } from '~/config/auth.config';
import { endpointConfig } from '~/config/endpoint.config';
import { environment } from '~/envs/environment';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { routes } from './app/app.routes';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),

    crmProvideAuth(authConfig),
    crmProvideEndpoint(endpointConfig),
    crmProvideTranslate(),
    crmProvideModal(),

    { provide: CrmAppConfig, useValue: appConfig },
    { provide: CrmUserService, useExisting: UserService },
  ],
}).catch((err) => console.error(err));
