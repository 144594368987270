import * as i0 from '@angular/core';
import { input, computed, output, inject, Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { CrmElementIdPipe } from 'common-module/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzMenuDirective, NzSubMenuComponent, NzMenuItemComponent } from 'ng-zorro-antd/menu';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { filter, map } from 'rxjs';

/**
 * Menu component for main navigation in app
 */
const _forTrack0 = ($index, $item) => $item.key;
function CrmMenuComponent_For_2_Conditional_0_For_5_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 5);
  }
  if (rf & 2) {
    const child_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("nzType", child_r3.icon);
  }
}
function CrmMenuComponent_For_2_Conditional_0_For_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 4);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵlistener("click", function CrmMenuComponent_For_2_Conditional_0_For_5_Template_li_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.navigated$.emit());
    });
    i0.ɵɵtemplate(3, CrmMenuComponent_For_2_Conditional_0_For_5_Conditional_3_Template, 1, 1, "i", 5);
    i0.ɵɵelementStart(4, "span");
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "crmTranslate");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const child_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassProp("crm-menu__menu-item--active", child_r3.isActive);
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 8, child_r3.key, "side-menu"))("routerLink", child_r3.route)("nzSelected", child_r3.isActive)("nz-tooltip", ctx_r1.isCollapsed() ? i0.ɵɵpipeBind1(2, 11, child_r3.key) : null);
    i0.ɵɵadvance(3);
    i0.ɵɵconditional(child_r3.icon ? 3 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(6, 13, child_r3.key), "");
  }
}
function CrmMenuComponent_For_2_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 1);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementStart(3, "ul");
    i0.ɵɵrepeaterCreate(4, CrmMenuComponent_For_2_Conditional_0_For_5_Template, 7, 15, "li", 3, _forTrack0);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 4, item_r4.key, "side-menu"))("nzOpen", item_r4.isActive)("nzIcon", item_r4.icon)("nzTitle", i0.ɵɵpipeBind1(2, 7, item_r4.key));
    i0.ɵɵadvance(4);
    i0.ɵɵrepeater(item_r4.children);
  }
}
function CrmMenuComponent_For_2_Conditional_1_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 5);
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("nzType", item_r4.icon);
  }
}
function CrmMenuComponent_For_2_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 6);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵlistener("click", function CrmMenuComponent_For_2_Conditional_1_Template_li_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.navigated$.emit());
    });
    i0.ɵɵtemplate(3, CrmMenuComponent_For_2_Conditional_1_Conditional_3_Template, 1, 1, "i", 5);
    i0.ɵɵelementStart(4, "span");
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "crmTranslate");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("crm-menu__item--active", item_r4.isActive);
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 8, item_r4.key, "side-menu"))("routerLink", item_r4.route)("nzSelected", item_r4.isActive)("nz-tooltip", ctx_r1.isCollapsed() ? i0.ɵɵpipeBind1(2, 11, item_r4.key) : null);
    i0.ɵɵadvance(3);
    i0.ɵɵconditional(item_r4.icon ? 3 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(6, 13, item_r4.key), "");
  }
}
function CrmMenuComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmMenuComponent_For_2_Conditional_0_Template, 6, 9, "li", 1)(1, CrmMenuComponent_For_2_Conditional_1_Template, 7, 15, "li", 2);
  }
  if (rf & 2) {
    const item_r4 = ctx.$implicit;
    i0.ɵɵconditional(item_r4.children ? 0 : 1);
  }
}
class CrmMenuComponent {
  constructor() {
    this.hostClass = 'crm-menu';
    this.menuCollapsed = input(false);
    this.theme = input('light');
    this.mode = input('inline');
    this.prefix = input();
    this.items = input([]);
    this.isCollapsed = computed(() => {
      switch (this.mode()) {
        case 'horizontal':
          return undefined;
        default:
          return this.menuCollapsed();
      }
    });
    this.navigated$ = output();
    this.resolvedItems = computed(() => {
      return this.items().map(item => {
        const segments = [];
        const prefix = this.prefix();
        if (prefix) {
          segments.push('/', prefix);
        }
        if (item.routeKey) {
          segments.push('/', item.routeKey);
        }
        const route = segments.join('');
        const children = item.children?.length ? this.resolveChildren(route, this.url(), item.children) : undefined;
        const isActive = children ? children.some(child => child.isActive) : this.isItemActive(route, this.url());
        return {
          key: item.key,
          icon: this.resolveIcon(isActive, item.icon),
          route,
          isActive,
          children
        };
      });
    });
    this.router = inject(Router);
    this.url = toSignal(this.router.events.pipe(filter(event => event instanceof NavigationEnd), map(event => event.url)), {
      initialValue: this.router.url
    });
  }
  resolveChildren(parent, url, items) {
    if (!items?.length) {
      return undefined;
    }
    return items.map(item => {
      const route = `${parent}/${item.routeKey}`;
      const isActive = this.isItemActive(route, url);
      return {
        key: item.key,
        icon: this.resolveIcon(isActive, item.icon),
        isActive,
        route
      };
    });
  }
  isItemActive(path, url) {
    return !!url?.startsWith(path);
  }
  resolveIcon(isActive, icon) {
    if (!icon) {
      return '';
    }
    if (typeof icon === 'string') {
      return icon;
    }
    return isActive ? icon.selected ?? icon.base : icon.base;
  }
  static {
    this.ɵfac = function CrmMenuComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmMenuComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmMenuComponent,
      selectors: [["crm-menu"]],
      hostVars: 2,
      hostBindings: function CrmMenuComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        menuCollapsed: [1, "menuCollapsed"],
        theme: [1, "theme"],
        mode: [1, "mode"],
        prefix: [1, "prefix"],
        items: [1, "items"]
      },
      outputs: {
        navigated$: "navigated$"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 3,
      consts: [["nz-menu", "", 3, "nzMode", "nzTheme", "nzInlineCollapsed"], ["nz-submenu", "", 3, "id", "nzOpen", "nzIcon", "nzTitle"], ["nz-menu-item", "", "nzTooltipPlacement", "right", 1, "crm-menu__menu-item", 3, "id", "routerLink", "nzSelected", "crm-menu__item--active", "nz-tooltip"], ["nz-menu-item", "", "nzTooltipPlacement", "right", 1, "crm-menu__menu-item", "crm-menu__menu-subitem", 3, "id", "routerLink", "nzSelected", "nz-tooltip", "crm-menu__menu-item--active"], ["nz-menu-item", "", "nzTooltipPlacement", "right", 1, "crm-menu__menu-item", "crm-menu__menu-subitem", 3, "click", "id", "routerLink", "nzSelected", "nz-tooltip"], ["nz-icon", "", 3, "nzType"], ["nz-menu-item", "", "nzTooltipPlacement", "right", 1, "crm-menu__menu-item", 3, "click", "id", "routerLink", "nzSelected", "nz-tooltip"]],
      template: function CrmMenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "ul", 0);
          i0.ɵɵrepeaterCreate(1, CrmMenuComponent_For_2_Template, 2, 1, null, null, _forTrack0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzMode", ctx.mode())("nzTheme", ctx.theme())("nzInlineCollapsed", ctx.isCollapsed());
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.resolvedItems());
        }
      },
      dependencies: [CrmTranslatePipe, NzMenuDirective, CrmElementIdPipe, NzSubMenuComponent, NzMenuItemComponent, NzIconDirective, NzTooltipDirective, RouterLink],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmMenuComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-menu',
      template: `
    <ul
      nz-menu
      [nzMode]="mode()"
      [nzTheme]="theme()"
      [nzInlineCollapsed]="isCollapsed()"
    >
      @for (item of resolvedItems(); track item.key) {
        @if (item.children) {
          <li
            [id]="item.key | crmElementId: 'side-menu'"
            nz-submenu
            [nzOpen]="item.isActive"
            [nzIcon]="item.icon"
            [nzTitle]="item.key | crmTranslate"
          >
            <ul>
              @for (child of item.children; track child.key) {
                <li
                  class="crm-menu__menu-item crm-menu__menu-subitem"
                  [id]="child.key | crmElementId: 'side-menu'"
                  nz-menu-item
                  [routerLink]="child.route"
                  (click)="navigated$.emit()"
                  [nzSelected]="child.isActive"
                  [nz-tooltip]="
                    isCollapsed() ? (child.key | crmTranslate) : null
                  "
                  nzTooltipPlacement="right"
                  [class.crm-menu__menu-item--active]="child.isActive"
                >
                  @if (child.icon) {
                    <i nz-icon [nzType]="child.icon"></i>
                  }
                  <span> {{ child.key | crmTranslate }}</span>
                </li>
              }
            </ul>
          </li>
        } @else {
          <li
            [id]="item.key | crmElementId: 'side-menu'"
            class="crm-menu__menu-item"
            nz-menu-item
            [routerLink]="item.route"
            (click)="navigated$.emit()"
            [nzSelected]="item.isActive"
            [class.crm-menu__item--active]="item.isActive"
            [nz-tooltip]="isCollapsed() ? (item.key | crmTranslate) : null"
            nzTooltipPlacement="right"
          >
            @if (item.icon) {
              <i nz-icon [nzType]="item.icon"></i>
            }
            <span> {{ item.key | crmTranslate }}</span>
          </li>
        }
      }
    </ul>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, NzMenuDirective, CrmElementIdPipe, NzSubMenuComponent, NzMenuItemComponent, NzIconDirective, NzTooltipDirective, RouterLink]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmMenuComponent };
